<template>
  <div class="admin">
    <div class="row mb-5 app-header navbar" style="-webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);">
      <b-link class="navbar-brand pl-1" :href="url_landing">
        <img
          class="navbar-brand-full"
          src="/img/brand/logo_button.svg"
          width="160"
          height="50"
        />
        <img
          class="navbar-brand-mini"
          src="/img/brand/favicon.png"
          width="30"
          height="30"
        />
      </b-link>

      <ol class="list-items list-item1 d-sm-down-none p-1">
        <li>
          <a :href="'/where_is_my_shipment'" class="p-2 font-li text-muted"
            >DÓNDE ESTÁ MI ENVIO</a
          >
        </li>
        <li>
          <a :href="url_landing + '#servicios'" class="p-2 font-li text-muted"
            >SERVICIOS</a
          >
        </li>
        <li>
          <a
            target="_blank"
            href="https://documenter.getpostman.com/view/2178004/T1DjjK9j?version=latest"
            class="p-2 font-li text-muted"
            >API</a
          >
        </li>
        <li>
          <a target="_blank" href="https://api.whatsapp.com/send/?phone=5491162008771&text&app_absent=0" class="p-2 font-li text-muted">CONTACTO</a>
        </li>
      </ol>
      <ol class="list-items list-item2 d-xsm-down-none p-1">
        <li>
          <router-link to="/login" class="p-2 font-orange"
            >INICIA SESION</router-link
          >
        </li>
        <li>
          <router-link id="register" to="/register" class="p-2 text-muted"
            >CREAR CUENTA</router-link
          >
        </li>
      </ol>
    </div>
    <router-view></router-view>
    <div class="app-footer">
      <div class="content-footer">
        <div class="row">
          <div class="col-md-6">
            <div class="social-container text-white">
              <a href="#">
                <img width="40" src="/img/socialmedia/facebook2.png" alt="">
              </a>
              <a href="#">
                <img width="40" src="/img/socialmedia/instagram2.png" alt="">
              </a>
              <a href="#">
                <img width="40" src="/img/socialmedia/twitter2.png" alt="">
              </a>
            </div>
            <span id="contacto" class="footer-copy">
              <img
                src="/img/enviamelo-logo.svg"
                class="mobile-logo"
                alt="logo-white"
                width="200"
              />
              <br />
              <br />
              <p>Copyright 2019 - Todos los derechos reservados</p>
            </span>
          </div>

          <div class="col-md-3 pt-1">
            <ol class="list-footer">
              <li class="title-list">
                <b>PANEL</b>
              </li>
              <li>
                <router-link to="/register">Crear cuenta</router-link>
              </li>
              <li>
                <router-link style="color: #fff !important" to="/login"
                  >Iniciar Sesión</router-link
                >
              </li>
            </ol>
          </div>

          <div class="col-md-3 pt-1">
            <ol class="list-footer">
              <li class="title-list">
                <b>SECCIONES</b>
              </li>
              <li>
                <a href="/Politica_de_Privacidad_Enviamelo_090919.pdf"
                  >Politica de privacidad</a
                >
              </li>
              <li>
                <a href="/Terminos_y_Condiciones_Enviamelo_090919.pdf"
                  >Terminos y condiciones</a
                >
              </li>
              <li>
                <router-link to="/where_is_my_shipment"
                  >Dónde está mi envío</router-link
                >
              </li>
              <li>
                <a :href="url_landing + '#servicios'">Servicios</a>
              </li>
              <li>
                <a
                  href="https://documenter.getpostman.com/view/2178004/T1DjjK9j?version=latest"
                  >API</a
                >
              </li>
              <li>
                <a href="/#testimonios">Contacto</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
export default {
  name: "LandingContainer",
  components: {
    BLink
  },
  data() {
    return {
      url_landing: null,
    };
  },
  methods: {
    go_home() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.url_landing = process.env.VUE_APP_LANDING;
  },
};
</script>
<style lang="scss">
.list-items {
  li {
    font-size: 12px;
    margin-right: 14px;
    margin-left: 14px;
    font-weight: bold;
    :hover {
      border-radius: 30px;
      background: #f67f1c !important;
      color: #fff !important;
      padding: 15px !important;
    }
    #register{
      border-radius: 30px;
      border: #e2761d solid 1px;
      padding: 15px !important;
    }
    #register:hover{
      border: none;
    }
  }
}

.content-footer {
  margin-top: 5%;
  width: 100%;
  padding: 2%;
}
.social-container {
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
.social-container a {
  padding-right: 60px;
}
.mobile-logo {
  width: 40%;
}
.list-footer {
  display: flex;
  flex-direction: column;
  list-style: none;
  li {
    margin: 4px;
    a {
      color: #fff;
      font-size: 1rem;
      padding-bottom: 10px;
    }
  }
}
.title-list {
  font-size: 1.3rem !important;
  margin-bottom: 10px;
}
.app-footer {
  background: unset;
  border-top: none;
  height: auto;
  display: flex;
  align-items: center;
  background-image: url("/img/footer.png");
  background-size: cover;
  color: white;
  margin-left: 0 !important;
}
.list-items {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: 0;
}
.list-item2 {
  float: right;
}
.list-items > li {
  margin-right: 3em;
}

.text-muted{
  color: #7b7b81 !important;
}

.font-orange {
  color: #f67f1c !important;
}
.border-orange {
  border: 2px solid #f58f3a !important;
}
.navbar-brand-mini {
  display: none;
}

.md-theme-default a:not(.a) {
  color: white;
}

@media (min-width: 1281px) {
  .list-items > li {
    margin-right: 2em;
  }
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .d-sm-down-none {
    display: none !important;
  }
  .title-list {
    font-size: 1.2rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 1.1rem;
  }

  .content-footer {
    margin-top: 12%;
  }
  .content-footer img {
    width: 50%;
  }
  .content-footer i {
    font-size: 1.7em !important;
  }
  .social-container a {
    padding-right: 60px;
  }
  .list-items > li {
    margin-right: 1em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .d-sm-down-none {
    display: none !important;
  }
  .title-list {
    font-size: 1rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.9rem;
  }
  .content-footer {
    margin-top: 18%;
  }
  .content-footer img {
    width: 75%;
  }
  .content-footer i {
    font-size: 1.3em !important;
  }
  .social-container a {
    padding-right: 40px;
  }
  .content-footer {
    margin-top: 20%;
  }
  .list-items > li {
    margin-right: 0.5em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .d-sm-down-none {
    display: none !important;
  }
  .title-list {
    font-size: 0.9rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.8rem;
  }
  .content-footer {
    margin-top: 22%;
  }
  .content-footer img {
    width: 85%;
  }
  .content-footer i {
    font-size: 1.2em !important;
  }
  .social-container a {
    padding-right: 30px;
  }
  .content-footer {
    margin-top: 22%;
  }
  .list-items > li {
    margin-right: 0.5em;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .container {
    padding-top: 35px;
  }
  .d-sm-down-none {
    display: none !important;
  }
  .app-header .navbar-brand {
    width: 50px;
  }
  .navbar-brand-full {
    display: none !important;
  }
  .navbar-brand-mini {
    display: block;
  }
  .d-xsm-down-none {
    font-size: 0.5em;
  }
  .title-list {
    font-size: 0.8rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.7rem;
  }
  .content-footer {
    margin-top: 30%;
  }
  .content-footer i {
    font-size: 0.9em !important;
  }
  .footer {
    justify-content: space-around;
  }
  .social-container a {
    padding-right: 20px;
  }
  .footer .fa {
    color: white;
    font-size: 2em;
    margin: 0 0.5em;
  }
  .content-footer {
    margin-top: 25%;
  }
  .list-items > li {
    margin-right: 1em;
  }
}

@media (min-width: 120px) and (max-width: 319px) {
  .app-header .navbar-brand {
    display: none;
  }
  .navbar-brand-full {
    display: none !important;
  }
  .navbar-brand-mini {
    display: block;
  }
  .d-xsm-down-none {
    font-size: 0.5em;
  }
  .title-list {
    font-size: 0.8rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.7rem;
  }
  .content-footer {
    margin-top: 28%;
  }
  .content-footer i {
    font-size: 0.9em !important;
  }
  .footer {
    justify-content: space-around;
  }
  .social-container a {
    padding-right: 20px;
  }
  .footer .fa {
    color: white;
    font-size: 2em;
    margin: 0 0.5em;
  }
  .content-footer {
    margin-top: 25%;
  }
  .list-items > li {
    margin-right: 1em;
  }
}
</style>
